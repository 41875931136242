<template>
	<div class="row-space-tbf" v-if="loaded">
		<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$router.push({name: 'users'})"><icon-arrow-back /></div></div>
		<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{user.id ? $t('profile.update_user') :  $t('users.create-new-user') }}</h1>
				<div class="action">
					<v-popover class="help" :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
						<a class="btn-tbf blue only-icon " :href="helpData.link" target="_blank" v-if="helpData">
							<div class="icon"><icon-question /></div>
						</a>

						<template slot="popover">
							<div class="simple-text">{{ $t(`help_links.${helpData.type}`) }}</div>
						</template>
					</v-popover>

					<button class="btn-tbf white only-icon" @click="$router.push({name: 'users'})"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{$t('users.user-details')}}</div>
					<div class="description">{{$t('users.description-user-details')}}</div>

					<div class="list">
						<div class="input-group profile">
							<div v-if="errorsBe.avatar" class="error-msg">{{ errorsBe.avatar.join(" | ") }}</div>
							<div class="upload-img update" onclick="document.getElementById('avatarFile').click();">
								<img v-if="urlAvatar" :src="urlAvatar" class="img-circle"/>
								<img v-else src="../../../assets/user-circle.svg" class="img-circle"/>
								<div class="text">
									<p class="title">{{$t('profile.profile_picture')}}</p>
									<div class="with-image"><icon-upload /><p>{{$t('profile.upload_new_picture')}}</p></div>
								</div>
								<input style="display:none" id="avatarFile" type="file" accept="image/png, image/jpg, image/jpeg, image/svg" v-on:change="onAvatarChange">
							</div>
						</div>
					</div>
				</div>
				<div class="form">
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{$t('profile.first_name')}}*</label>
							<div v-if="$v.user.first_name.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.first_name" class="error-msg">{{ errorsBe.first_name.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.user.first_name.$error}">
							<div class="icon-left"><icon-people /></div>
							<div class="icon-right" v-if="user.first_name != ''" @click.stop="user.first_name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('profile.first_name_placeholder')" class="input-text" v-model="user.first_name">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{$t('profile.last_name')}}*</label>
							<div v-if="$v.user.last_name.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.last_name" class="error-msg">{{ errorsBe.last_name.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.user.last_name.$error}">
							<div class="icon-left"><icon-people /></div>
							<div class="icon-right" v-if="user.last_name != ''" @click.stop="user.last_name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('profile.name_placeholder')" class="input-text" v-model="user.last_name">
						</div>
					</div>
					<div class="input-group w-50" v-if="canChangeDummy">
						<div class="label-header">
							<label class="label">{{ $t('profile.dummy_question') }}</label>
						</div>
						<div class="input-box bor-0">
							<div class="checkbox-list">
								<div class="checkbox-item m-4" @click="user.dummy_account = 1, user.email = ''" v-bind:class="{active: user.dummy_account === 1}">
									<div class="name">{{ $t('general.no') }}</div>
								</div>
								<div class="checkbox-item m-4" @click="user.dummy_account = 0, user.email = ''" v-bind:class="{active: user.dummy_account === 0}">
									<div class="name">{{ $t('general.yes') }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="input-group" :class="[$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50', {disabled: user.dummy_account}]">
						<div class="label-header">
							<label class="label">{{$t('profile.email')}}*</label>
							<div v-if="$v.user.email.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.email" class="error-msg">{{ errorsBe.email.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.user.email.$error || error_email}">
							<div class="icon-left"><icon-email /></div>
							<div class="icon-right" v-if="user.email != ''" @click.stop="user.email = ''"><icon-close class="icon-clear" /></div>
							<input type="email" :placeholder="$t('profile.email_placeholder')" class="input-text" v-model="user.email" @change="error_email = false" :disabled="user.dummy_account == 1">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{$t('roles.role')}}</label>
							<div class="create-new" @click="showModal('role')">{{$t('roles.add_role')}}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-role /></div>
							<div class="icon-right" v-if="selectedRoles.length" @click.stop="selectedRoles = []"><icon-close class="icon-clear" /></div>
							<multiselect 
							class="hide-tags multiselect_checkbox"
							v-bind:class="{populate: selectedRoles.length}"
							v-model="selectedRoles"
							:options="optionsRoles"
							:allow-empty="true"
							:show-labels="false"
							:multiple="true" 
							:close-on-select="false"
							track-by="id" 
							label="name"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{$t('roles.choose_role')}}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
									<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
								</template>
								<template slot="option" slot-scope="props">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
									</div>
								</template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{$t('users.rights')}}*</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.selectedRight.$error}">
							<div class="icon-left"><icon-gears /></div>
							<multiselect 
							v-model="selectedRight"
							:options="optionsRights"
							:allow-empty="false"
							:show-labels="false"
							:multiple="false" 
							:close-on-select="true"
							:disabled="isAdmin ? true : (currentBounceRole == 'admin' ? !$auth.user().is_entrepreneur : false)"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">{{$t('users.choose_rights')}}</span>
								</template>
								<template slot="option" slot-scope="props">{{ $t(`users.rights_name.${props.option}`) }}</template>
								<template slot="singleLabel" slot-scope="{ option }">{{ $t(`users.rights_name.${option}`) }}</template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>
							</multiselect>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{$t('users.notifications-create')}}</div>
					<div class="description">{{$t('users.notifications-create-desc')}}</div>
				</div>
				<div class="form">
					<div class="notifications-list crud-page">
						<div class="notification-row level-1" v-bind:class="{active: selectedNotifications.some(element => proceduresNotifications.includes(element))}" @click="selectAllProcedures">
							<div class="checkbox">
								<div class="checkmark"></div><span class="text">{{ $t('settings-notifications.procedures') }}</span>
							</div>
						</div>
						<div class="notification-row level-2" v-for="notification in proceduresNotifications" v-bind:class="{active: selectedNotifications.includes(notification)}" @click="toggleSelected(notification)">
							<div class="checkbox">
								<div class="checkmark"></div><span class="text">{{ $t(`settings-notifications.${notification}`) }}</span>
							</div>
						</div>

						<div class="notification-row level-1" v-bind:class="{active: selectedNotifications.some(element => processesNotifications.includes(element))}" @click="selectAllProcesses">
							<div class="checkbox">
								<div class="checkmark"></div><span class="text">{{ $t('settings-notifications.processes') }}</span>
							</div>
						</div>
						<div class="notification-row level-2" v-for="notification in processesNotifications" v-bind:class="{active: selectedNotifications.includes(notification)}" @click="toggleSelected(notification)">
							<div class="checkbox">
								<div class="checkmark"></div><span class="text">{{ $t(`settings-notifications.${notification}`) }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="form-submit">
				<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
				<button class="btn-tbf grey" @click="$router.push({name: 'users'})"><span class="text">{{ $t('general.cancel') }}</span></button>
				<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!user.id">
					<div class="loader"></div>
					<span class="text">{{$t('users.new_user')}}</span>
				</button>
				<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
					<div class="loader"></div>
					<span class="text">{{$t('users.update_user')}}</span>
				</button>
			</div>
		</div>
		<div class="space-right"></div>
	</div>
	<loader-users-create  v-else/>
</template>

<script>
	import IconArrowBack from '../../Icons/ArrowLong'
	import IconClose from '../../Icons/Close'
	import IconPeople from '../../Icons/People'
	import IconUpload from '../../Icons/Upload'
	import IconRole from '../../Icons/Role'
	import IconEmail from '../../Icons/Email'
	import IconGears from '../../Icons/Gears'
	import IconQuestion from '../../Icons/QuestionHelp'
	import LoaderUsersCreate from '../../PagesLoaders/UsersCreate'
	import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconArrowBack,
			IconClose,
			IconPeople,
			IconUpload,
			IconRole,
			IconEmail,
			LoaderUsersCreate,
			IconGears,
			IconQuestion
		},
		data() {
			return {
				loaded: false,
				canChangeDummy: false,
				user: {
					id: '',
					slug: '',
					avatar: '',
					first_name: '',
					last_name: '',
					email: '',
					dummy_account: 0
				},
			  	optionsRoles: [],
			  	selectedRoles: [],
				urlAvatar: '',
				error_message: '',
				selectedRight: '',
				optionsRights: [],
				isAdmin: false,
				currentBounceRole: '',
				error_email: false,
				selectedNotifications: [],
				proceduresNotifications: ['new_procedure', 'assigned_to_procedure', 'edit_live_procedure', 'change_to_draft_procedure', 'change_to_live_procedure', 'delete_procedure', 'add_improvement_proposal_to_procedure', 'change_status_improvement_proposal', 'create_admin_procedures'],
				processesNotifications: ['new_version_process', 'assigned_to_process', 'edit_live_process', 'add_improvement_proposal_to_process', 'delete_process', 'create_admin_processes'],
				errorsBe: {},
				helpData: this.$store.getters['help_links/currentLink'](this.$route.name)
			};
		},
		async mounted(){
			await this.getFilters();

			this.$root.$on('updateListProcedureCreate', (data) => {
				this.optionsRoles.push(data)
				this.selectedRoles.push(data)
			})

			if(this.$route.params.slug){
				await this.getUserData()
			}else{
				setTimeout(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				}, 0)
			}
		},
		validations: {
			user: {
				first_name: {required},
				last_name: {required},
				email: { required: requiredIf( function (model) {
					return this.user.dummy_account == 0
				})}
			},
			selectedRight: {required}
		},
		methods: {
			async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {roles: true, rights: true }})
				.then(({data}) => {
					this.optionsRoles = data.data.roles
					this.optionsRights = data.data.rights

					if(this.$auth.user().rights_name.includes('admin')){
						this.optionsRights.splice(this.optionsRights.indexOf('admin'), 1);
					}

					this.selectedRight = this.optionsRights.find(el => el == 'user')
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			async getUserData(){
				await axios.get(`/users/${this.$route.params.slug}/edit`)
				.then(({data}) => {
					let userData = data.data

					this.urlAvatar = userData.avatar
					this.user.first_name = userData.first_name 
					this.user.last_name = userData.last_name 
					this.user.email = userData.dummy_account ? "" : userData.email
					this.user.id = userData.id
					this.user.slug = userData.slug
					this.isAdmin = userData.right == "master_admin"
					this.currentBounceRole = userData.right
					this.selectedNotifications = userData.mail_notifications
					this.user.dummy_account = userData.dummy_account
					this.canChangeDummy = userData.dummy_account ? true : false

					if(this.isAdmin){ this.optionsRights.push('master_admin') }

					this.selectedRight = this.optionsRights.find(el => el == userData.right) ? this.optionsRights.find(el => el == userData.right) : userData.right

					userData.roles.map((role) => {
						this.selectedRoles.push(this.optionsRoles.find(el => el.id == role))
					})
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
			showModal(type){
				this.$root.$emit('open_modal', type, false, false);
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';
				this.error_email = false,

				this.$v.$touch()
				if(!this.$v.$invalid){
					var selectedRoles = this.selectedRoles.length ? this.selectedRoles.map(function(a) {return a.id;}) : []

					let objData = new FormData();

					if(this.user.avatar) { objData.append('avatar', this.user.avatar) }
					objData.append('first_name', this.user.first_name)
					objData.append('last_name', this.user.last_name)
					objData.append('email', this.user.email)
					objData.append('dummy_account', this.user.dummy_account ? 1 : 0)
					objData.append('right', this.selectedRight)

					if(this.selectedNotifications.length) {
						this.selectedNotifications.map((el, index) => {
							objData.append(`mail_notifications[${index}]`, el)
						})
					}

					if(selectedRoles.length) {
						selectedRoles.map((el, index) => {
							objData.append(`roles[${index}]`, el)
						})
					}

					if(type == 'create'){
						this.createUser(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateUser(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('users.new_user') : this.$t('users.update_user')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createUser(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/users/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							this.$auth.fetch();
							this.$router.push({name: 'users'})
						}, 500)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
			updateUser(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				objData.append('_method', 'PATCH')

				axios.post(`/users/${this.user.slug}`, objData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$auth.fetch()
						setTimeout(()=>{
							this.$router.push({name: 'users'})
						}, 500)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			addSkill(){
				var skillObj = {
					level: this.selectedSkillLevel ? this.selectedSkillLevel.key : 'undefined',
					id: this.selectedSkill.id,
					name: this.selectedSkill.name
				}

				this.savedSkills.push(skillObj)

				this.optionsSkills.find(el => el.id == this.selectedSkill.id).$isDisabled = true

				this.selectedSkill = ''
				this.selectedSkillLevel = ''
			},
			onAvatarChange(e){
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				this.urlAvatar = URL.createObjectURL(files[0]);
				this.user.avatar = files[0]
			},
			toggleSelected(type){
				if(this.selectedNotifications.includes(type)){
					this.selectedNotifications.splice(this.selectedNotifications.indexOf(type), 1)
				}else{
					this.selectedNotifications.push(type)
				}
			},
			selectAllProcedures(){
				if(this.selectedNotifications.some(element => this.proceduresNotifications.includes(element))){
					this.proceduresNotifications.map(el => {
						if(this.selectedNotifications.includes(el)){
							this.selectedNotifications.splice(this.selectedNotifications.indexOf(el), 1)
						}
					})
				}else{
					this.proceduresNotifications.map(el => {
						this.selectedNotifications.push(el)
					})
				}
			},
			selectAllProcesses(){
				if(this.selectedNotifications.some(element => this.processesNotifications.includes(element))){
					this.processesNotifications.map(el => {
						if(this.selectedNotifications.includes(el)){
							this.selectedNotifications.splice(this.selectedNotifications.indexOf(el), 1)
						}
					})
				}else{
					this.processesNotifications.map(el => {
						this.selectedNotifications.push(el)
					})
				}
			}
		}
	};
</script>

<style lang="scss">
	.upload-img.update{
		display: flex;
	}
</style>